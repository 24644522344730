import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SanitizeHTML from '../components/SanitizeHTML';
import SignupButton from '../components/SignupButton';

export const BlogPostTemplate = ({ title, html, showSignupButton }) => {
  return <>
    <Layout>
      <div className="container">
        <h1>{title}</h1>
        <SanitizeHTML html={html} />

        {showSignupButton && <SignupButton />}
      </div>
    </Layout>
  </>
}

const BlogPostPage = ({ data }) => {
  const { title, showSignupButton } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;

  return <BlogPostTemplate
    title={title}
    html={html}
    showSignupButton={showSignupButton}
  />
}

export default BlogPostPage;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title,
        showSignupButton
      }
    }
  }
`;
